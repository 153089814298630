.faq-plus-icon {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);

    .stripe-1 {
      opacity: 0;
    }
  }
}

.faq-basic {
  .ReactCollapse--collapse {
    transition: height 300ms;
  }
}

.back.w-inline-block {
  cursor: pointer;
}

.error-highlight {
  border: 1px solid red !important;
}

.mtx-slider {
  .rc-slider-rail {
    height: 5px;
    border-radius: 5px;
    background-color: rgba(41, 51, 92, 0.2);
    background-image: -webkit-gradient(linear, left top, right top, from(#ffd830), color-stop(0%, #e5e5e5));
    background-image: linear-gradient(90deg, #ffd830, #e5e5e5 0%);
  }
  .rc-slider-handle {
    top: 0;
    width: 24px;
    height: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    cursor: ew-resize;

    &:focus {
      box-shadow: none;
    }
  }

  .rc-slider-track {
    background: rgb(62, 120, 255);
  }
}
.slider-wrap {
  margin: 0 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mobile-onboarding {
  .hidden-on-app {
    display: none !important;
  }
}
.onboarding  {
  font-weight: 600;
  font-family: Manrope, sans-serif;

  ::placeholder {
    font-weight: 600;
  }

  .menu-button {

  }

  a {
    cursor: pointer;
  }

  .nav-component.static {
    position: relative;
  }

  .rc-slider-step {
    cursor: pointer;
  }

  .body {
    font-weight: 600;
    font-family: Manrope, sans-serif;
  }

  .input-slider {
    padding: 8px 12px;
  }

  .announcement-bar {
    transition: background-color 0.5s ease-in-out;

    &.active {
      background-color: rgb(65, 65, 71);
    }
  }

  .main-nav_nav-link {
    &.with-anim {
      img {
        transition: all 0.2s ease-in-out;
        transform: translate3d(0px, 0px, 460px);
        transform-style: preserve-3d;
      }

      &:hover {
        img {
          transform: translate3d(5px, -2px, 460px);
        }
      }
    }
  }

  .menu-button.w-nav-button {
    .hamburger-line {
      transition: transform 0.2s ease-in-out;
      transform-style: preserve-3d;
    }
    .first {
      transform: translate3d(0px, 6px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg , 0deg);
    }
    .second {
      transform: translate3d(0px, -6px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }

    &.active {
      .first {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
      }
      .second {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
      }
    }
  }

  .nav-menu.w-nav-menu {
    transition: transform 0.3s ease-in-out;

    &.active {
      transform: translateY(0);
    }
  }

  .link-block, .close {
    cursor: pointer;
  }

  .static-calc-wrap {
    height: 78px;
  }

  .secondary-nav.sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .ReactCollapse--collapse {
    transition: all 0.2s ease-in-out 0s;
  }

  .custom-success-box {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .w-form-done {
      display: block;
    }
  }

  .custom-checkbox-2.w--redirected-checked:not(.complicated) {
    background-position: 97% 45%;
  }

  .custom-select.field.w-input {
    display: flex;
    align-items: center;

    & > div {
      border: none;
      display: flex;
      flex: 1;

      & > div {
        padding-left: 0;
      }
    }
  }

  .w-tab-content.noHidden {
    overflow: visible;
  }

  .w-dropdown:hover {
    .main-nav_dropdown_list {
      display: block;
    }
  }

  .navbar.flex.w-nav {
    position: fixed;
    top: 0;
  }

  .x-button {
    display: flex;
  }

  .notification-wrapper {
    margin-bottom: 15px;
  }

  .section.fullheight {
    height: calc(100vh - 80px);
  }

  .go-top {
    cursor: pointer;
    transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transition: all 0.2s ease-in-out 0s;

    &.active {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
    }
  }

  .w-form-fail {
    display: block;
  }

  .dropdown-list {
    position: absolute;
    z-index: 99999;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.07);
  }

  .utility-page-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
  }

  .terms-condition {
    display: inline-block;
    text-decoration: underline;

    p {
      color: #fff !important;
      padding-left: 5px;
      font-size: 15px;
    }
  }

  .section {
    position: relative;
    z-index: 1;
  }

  .error {
    color: rgb(255, 59, 48);
  }

  @media screen and (max-width: 479px) {
    .custom-checkbox-2.w--redirected-checked:not(.complicated) {
      background-position: 94% 50%;
    }
  }

  @media screen and (max-width: 991px) {
    .container._70 {
      max-width: 100%;
    }
  }
}

.accordion__item.active svg {
  transform: rotate(180deg);
}

.onboarding {
  .accordion-right-block.desktop {
    transform: translate(60%, 0px);
    opacity: 0;
    z-index: 1;

    &.active {
      z-index: 2;
      opacity: 1;
      transform: translate(0%, 0px);
      transition: all 300ms ease;
    }

    &.prev {
      opacity: 1;
      transform: translate(0%, 0px);
    }
  }

  .animation-wrapper {
    &.desktop {
      .animated-text-wrapper {
        .highlighted-text {
          opacity: 0;
          transform: translateY(25px);
          animation-name: hide;
          animation-duration: 300ms;
          animation-fill-mode: both;
          animation-timing-function: ease-in-out;

          &:nth-child(2) {
            animation-delay: 100ms;
          }
        }

        &.active {
          .highlighted-text {
            animation-name: show;
            animation-delay: 200ms;

            &:nth-child(2) {
              animation-delay: 300ms;
            }
          }
        }
      }
    }

    &.mobile {
      .animated-text-wrapper {
        .highlighted-text {
          opacity: 0;
          transform: translateY(50px);
          animation-name: hide;
          animation-duration: 300ms;
          animation-fill-mode: both;
          animation-timing-function: ease-in-out;

          &:nth-child(2) {
            animation-delay: 100ms;
          }
          &:nth-child(3) {
            animation-delay: 200ms;
          }
        }

        &.active {
          .highlighted-text {
            animation-name: show;
            animation-delay: 200ms;

            &:nth-child(2) {
              animation-delay: 300ms;
            }
            &:nth-child(3) {
              animation-delay: 400ms;
            }
          }
        }
      }
    }
  }

  .button {
    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}


@keyframes show {
  from {
    opacity: 0;
    transform: translateY(25px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-25px);
  }
}
